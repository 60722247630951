import React, { ReactNode, useEffect, useState } from 'react';
import { cn } from '@/util/styleUtils';
import { Button } from '@/components/ui/button';
import { ContentWidth, Dialog, DialogContent } from '@/components/ui/dialog';

export type Level = 'warning' | 'danger' | 'info' | 'success';
type ConfirmModalProps = {
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
    message: ReactNode;
    title?: string;
    continueText?: string;
    cancelText?: string;
    level?: Level;
    icon?: ReactNode;
    additionalAction?: ReactNode;
    maxWidth?: ContentWidth;
};
export const ConfirmModal = ({
    open,
    onCancel,
    onSuccess,
    message,
    continueText = 'Continue',
    cancelText = 'Cancel',
    level,
    icon,
    title = 'Warning',
    additionalAction,
    maxWidth = 'md',
}: ConfirmModalProps) => {
    const [successLoading, setSuccessLoading] = useState(false);
    useEffect(() => {
        if (open) {
            setSuccessLoading(false);
        }
    }, [open]);
    return (
        <Dialog open={open} modal={true}>
            <DialogContent maxWidth={maxWidth} hideCloseButton>
                <div className={'space-y-8'}>
                    <div className={'space-y-2'}>
                        <header className="">
                            <p className={cn('text-xl font-bold')}>
                                {icon} {title}
                            </p>
                        </header>
                        <section className="">{message}</section>
                    </div>
                    <footer className="flex justify-end space-x-4">
                        {additionalAction}
                        <Button
                            type="button"
                            variant={'outlined'}
                            onClick={() => {
                                onCancel();
                            }}
                            mobileFullWidth
                        >
                            {cancelText}
                        </Button>
                        <Button
                            type="button"
                            mobileFullWidth
                            isLoading={successLoading}
                            variant={level === 'danger' ? 'destructive' : undefined}
                            onClick={() => {
                                setSuccessLoading(true);
                                onSuccess();
                            }}
                        >
                            {continueText}
                        </Button>
                    </footer>
                </div>
            </DialogContent>
        </Dialog>
    );
};
