import React, { ReactNode, useEffect, useState } from 'react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { CalendarDays, HelpCircle, HomeIcon, Loader2, LogOutIcon, PackageOpen, User2Icon } from 'lucide-react';
import Link from 'next/link';
import { useLogout } from '@/components/AuthButtons';
import { usePathname } from 'next/navigation';
import { cn } from '@/util/styleUtils';
import useAuth from '@/hooks/useAuth';
import { ConfirmModal } from '@/components/views/ConfirmModal';

type NavLinkProps = { href: string; children: ReactNode };
const NavLink = React.forwardRef<
    React.ElementRef<typeof Link>,
    React.ComponentPropsWithoutRef<typeof Link> &
        NavLinkProps & {
            inset?: boolean;
        }
>(({ href, children }, ref) => {
    const pathname = usePathname();
    const selected = pathname == href;
    return (
        <Link
            ref={ref}
            href={href}
            className={cn('flex items-center space-x-1.5 whitespace-nowrap rounded px-2 py-1.5 pr-6', {
                'bg-blue text-white': selected,
                'text-gray-900 hover:bg-green-light': !selected,
            })}
        >
            {children}
        </Link>
    );
});

export type ProfileDropdownMenuProps = {};

export function ProfileDropdownMenu({}: ProfileDropdownMenuProps) {
    const pathname = usePathname();
    const { subscriber } = useAuth();
    const { handleLogout, loading: logoutLoading } = useLogout();
    const [open, setOpen] = useState(false);
    const [signOutConfirmOpen, setSignOutConfirmOpen] = useState(false);
    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    return (
        <div>
            <DropdownMenu open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
                <DropdownMenuTrigger asChild>
                    <div
                        className={`flex cursor-pointer items-center justify-between rounded-full bg-orange text-white transition-colors hover:bg-dark-orange`}
                    >
                        <Avatar size={'sm'}>
                            <AvatarFallback>
                                {subscriber?.name ? subscriber.name.substring(0, 1) : <User2Icon />}
                            </AvatarFallback>
                        </Avatar>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align={'end'} side={'bottom'} className={'space-y-1'}>
                    <DropdownMenuItem asChild>
                        <NavLink href={'/dashboard'}>
                            <HomeIcon className={'h-4 w-4'} />
                            <span>Dashboard</span>
                        </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <NavLink href={'/schedule'}>
                            <CalendarDays className={'h-4 w-4'} />
                            <span>My schedule</span>
                        </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <NavLink href={'/account/profile'}>
                            <User2Icon className={'h-4 w-4'} />
                            <span>My Account</span>
                        </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <NavLink href={'/account/orders'}>
                            <PackageOpen className={'h-4 w-4'} />
                            <span>Order History</span>
                        </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <NavLink href={'/contact-us'}>
                            <HelpCircle className={'h-4 w-4'} />
                            <span>Help</span>
                        </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                        onClick={(e) => {
                            e.preventDefault();
                            setSignOutConfirmOpen(true);
                        }}
                        variant={'destructive'}
                        className={'cursor-pointer space-x-1.5 text-base'}
                    >
                        {logoutLoading ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                        ) : (
                            <LogOutIcon className={'h-4 w-4'} />
                        )}
                        <span>Sign out</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <ConfirmModal
                level={'danger'}
                title={'Sign out'}
                open={signOutConfirmOpen}
                onCancel={() => setSignOutConfirmOpen(false)}
                onSuccess={() => handleLogout()}
                message={'Are you sure you want to sign out?'}
                cancelText={'Cancel'}
                continueText={'Yes, sign out'}
                maxWidth={'lg'}
            />
        </div>
    );
}

ProfileDropdownMenu.displayName = ProfileDropdownMenu;
